import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useTranslation from 'hooks/use-translation';

import Wrapper from 'components/wrapper';
import MetaTags from 'components/meta-tags';
import GlobalFooter from 'components/global-footer-rebrand';
import MarketingHeader from 'marketing/components/marketing-header';
import { PageContentContainer, H1, P } from 'info/terms';

export default function AccessibilityPage() {
  const { t, Trans } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));

  return (
    <Wrapper>
      <MetaTags variant='accessibility' />
      <MarketingHeader variant='legal' />

      <PageContentContainer isMobile={isMobile} height={isMobile ? '73%' : '90%'}>
        <H1>{t('accessibility.accessibility', 'Accessibility Statement')}</H1>
        <br />
        <P>
          <Trans i18nKey='terms.p1'>
            For <a href='http://www.dutchie.com'>www.dutchie.com</a> and all affiliated technology.
          </Trans>
        </P>
        <P>
          <Trans i18nKey='terms.p2'>
            At Dutchie, we are committed to ensuring a great experience for all of our users. We understand that a key
            element of ensuring a great experience for everyone is digital accessibility, for people with specific
            needs. At Dutchie, we aim to meet the Web Content Accessibility Guidelines 2.1 (“WCAG&nbsp;2.1”), Level AA,
            and we’re continuously focused on developing and maintaining software to expand accessibility for our users.
            We always strive to create a user experience that is suitable for everyone by applying these relevant
            accessibility standards in our software. To ensure we meet accessibility guidelines, we use tools and audits
            as part of a standard operating procedure in our software development. We also work with outside consultants
            who are experts in accessibility to periodically review our existing and newly-developed features on our
            platform for accessibility.
          </Trans>
        </P>
        <P>
          <Trans i18nKey='terms.p3'>
            As the law and accessibility standards continue to change and we strive to deliver the most up-to-date
            content, please understand that our efforts to improve online accessibility are ongoing.
          </Trans>
        </P>
        <P>
          <Trans i18nKey='terms.p4'>
            If you encounter any accessibility barriers in using the Dutchie website, or if you have any comments,
            questions, or concerns about the website’s accessibility, please contact us at{' '}
            <a href='mailto:accessibility@dutchie.com'>accessibility@dutchie.com</a> or{' '}
            <a href='tel:18668388244'>1-866-838-8244</a>. We will work with you to ensure that you have full and equal
            access to the services we provide on the Dutchie website.
          </Trans>
        </P>
        <P>
          <Trans i18nKey='terms.p5'>
            We thank you for your feedback in enhancing the accessibility of our software and we look forward to
            providing the most frictionless experience possible!
          </Trans>
        </P>
      </PageContentContainer>

      <GlobalFooter />
    </Wrapper>
  );
}
